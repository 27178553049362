import React, { Component, cloneElement } from 'react';
import { RadioButton, CheckBox, DetailsMenu, Scrubber, CompositeScrubber, CSSRange, LabelRadioGroup, TextButton} from "./";
import { Select, Input, Button, MoreActions, ResetButton } from "@cargo/ui-kit";
import { Formik, Field } from 'formik';
import ColorField from "./color-picker-field";

import AlignContentLeftIcon from "@cargo/common/icons/align-content-left.svg";
import AlignContentCenterIcon from "@cargo/common/icons/align-content-center.svg";
import AlignContentRightIcon from "@cargo/common/icons/align-content-right.svg";
import AlignTextLeftIcon from "../../svg-icons/text-align-left.svg";
import AlignTextCenterIcon from "../../svg-icons/text-align-center.svg";
import AlignTextRightIcon from "../../svg-icons/text-align-right.svg";
import AlignContentTopIcon from "@cargo/common/icons/align-content-top.svg";
import AlignContentMiddleIcon from "@cargo/common/icons/align-content-middle.svg";
import AlignContentBottomIcon from "@cargo/common/icons/align-content-bottom.svg";
import AlignContentFill from "@cargo/common/icons/align-content-fill.svg";



const renderUIFromJSON = (options, activeOptions, parentComponent, customComponentMap = {})=>{

	const icons = {
		// Vertical Alignment
		alignContentTop: <AlignContentTopIcon/>,
		alignContentMiddle: <AlignContentMiddleIcon/>,
		alignContentBottom: <AlignContentBottomIcon/>,
		// Horizontal Alignment
		alignContentLeft: <AlignContentLeftIcon/>,
		alignContentCenter: <AlignContentCenterIcon/>,
		alignContentRight: <AlignContentRightIcon/>,
		alignContentFill: <AlignContentFill/>,

	}


	const GenerateIconFileComponent = (iconName) => {
		return icons[iconName];
	}

	if(!options) {
		return null;
	}

	return options.map((option, index)=>{

		const key = `${option.name || 'option'}-${index}-${option.type}`;
		let doNotRender = option.hidden || false;
		let disabled = false;
		let className = ''

		if(option.requirements){
			option.requirements.forEach((condition)=>{

				if( typeof condition.shouldBe == 'function'){
					if( !doNotRender){
						const shouldBe = condition.shouldBe(activeOptions?.[condition.option]);
						
						if( !shouldBe){
							doNotRender = true;
						}	
					}
					
				} else if(activeOptions?.[condition.option] !== condition.shouldBe) {
					doNotRender = true;
				}

			})
		}

		if(doNotRender){
			if( option.renderWhenDisabled === true ){
				disabled = true;
				doNotRender = false;
			}
		}

		if(option.onChange && parentComponent) {
			option.onChange = option.onChange.bind(parentComponent)
		}

		if(option.onScrubStart && parentComponent) {
			option.onScrubStart = option.onScrubStart.bind(parentComponent)
		}

		if(option.onScrubEnd && parentComponent) {
			option.onScrubEnd = option.onScrubEnd.bind(parentComponent)
		}

		if( disabled || option.disabled ){
			className+= ' disabled'
		}

		if( doNotRender ){
			className+= ' hidden'
		}

		let label  = option.labelName;
		if (option.customLabel && customComponentMap[option.customLabel]){

			label = cloneElement(
				customComponentMap[option.customLabel],
				{
					key: 'custom-label-'+option.name
				}
			);
		}		



		switch (option.type) {
			// for cases where we have one-off components, like the focal-point setter in wallpaper
			case 'custom':
				if( customComponentMap?.[option.name] ){
					return cloneElement(customComponentMap[option.name], {
						key,
						...option.props,						
						className: (customComponentMap[option.name].props?.className || '') + (options.props?.className || '') + className,
						name: option.name,
					})
				}
				break;

			case 'text-input':
			
				return <Field
					className={option.className ? option.className : className}
					key={key}
					component={Input}
					name={option.name}
					label={label}
					noBars={option.config === 'noBars' }
					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}
				/>

			// only backdrops should be using this one
			case 'range-input':
		
					
				return <Field
					className={className}
					key={key}
					component={Scrubber}
					name={option.name}
					label={label}
					loopValues={option.loopValues}

					min={option.min}
					max={option.max}
					step={option.step}
					suffix={option.suffix}
					allowUnclampedTextEntry={option.allowUnclampedTextEntry}
					allowCalc={false}
					allowVar={false}
					coerceNumberToString={false}

					addDefaultUnitToUnitlessNumber={false}

					onMount={option.onMount}

					numberOnlyMode={true}
					defaultUnit={''}
					allowedUnits={[]}	

					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}
				/>					
				

			case 'select':
				return <Field
					className={className}
						key={key}
						component={Select}
						prefix={label}
						name={option.name}

						isOverriding={option.isOverriding}
						overrideReset={option.overrideReset}
					>
						{option.values.map((choice, index)=>(
							<option key={key+'_'+index} value={choice.value}>{choice.labelName}</option>
						))}
					</Field>			

			case 'check-box':  

				return <Field
					className={option.className ? option.className : className}
					disabled={disabled}
					key={key}
					component={CheckBox}
					name={option.name}
					label={label}
					onMount={option.onMount}

					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}

				/>

			case 'css-range': 

				return <Field
					className={option.className ? option.className : className}
					key={key}
					component={CSSRange}
					label={label}
					name={option.name}
					unitSet={option.units}
					defaultUnit={option.unit}
					meta={{initialValue: ''+option.value+option.unit}}
					allowUnclampedTextEntry={option.allowUnclampedTextEntry}

					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}
				/>
			case 'scrubber':
				return <Field
					className={option.className ? option.className : className}
					key={key}

					component={Scrubber}
					name={option.name}
					label={label}
					suffix={option.suffix}
					min={option.min}
					max={option.max}
					step={option.step}
					pixelsPerDelta={option.pixelsPerDelta}
					allowCalc={option.allowCalc}
					allowVar={option.allowVar}
					cssTestProperty={option.cssTestProperty}
					addDefaultUnitToUnitlessNumber={option.addDefaultUnitToUnitlessNumber}
					onChange={option.onChange}
					onScrubEnd={option.onScrubEnd}
					onScrubStart={option.onScrubStart}					
					loopValues={option.loopValues}
					numberOnlyMode={option.numberOnlyMode}
					defaultUnit={option.defaultUnit}
					allowedUnits={option.allowedUnits}	
					allowUnclampedTextEntry={option.allowUnclampedTextEntry}

					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}
				/>
			case 'composite-scrubber':
				return <Field
					className={option.className ? option.className : className}
					key={key}

					component={CompositeScrubber}
					name={option.name}
					label={label}
					suffix={option.suffix}
					min={option.min}
					max={option.max}
					step={option.step}
					pixelsPerDelta={option.pixelsPerDelta}
					allowCalc={option.allowCalc}
					allowVar={option.allowVar}
					cssTestProperty={option.cssTestProperty}
					addDefaultUnitToUnitlessNumber={option.addDefaultUnitToUnitlessNumber}
					allowUnclampedTextEntry={option.allowUnclampedTextEntry}

					loopValues={option.loopValues}
					numberOnlyMode={option.numberOnlyMode}
					defaultUnit={option.defaultUnit}
					allowedUnits={option.allowedUnits}	

					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}
				/>					

			case 'radio':

				return <div className={`grid-columns-auto`} key={key}>
					<ResetButton 
						isOverriding={option.isOverriding} 
						overrideReset={option.overrideReset}
					/>
					<LabelRadioGroup 
						label={option.labelName}
						className={className}
						>
						{option.values.map((radioOption)=> {

							let radioLabel  = radioOption.labelName;
							if (radioOption.customLabel && customComponentMap[radioOption.customLabel]){
								radioLabel = cloneElement(
									customComponentMap[radioOption.customLabel],
									{
										key: 'custom-radio-label-'+radioOption.name
									}
								);								
							}

							return <Field
								className={className} 
								key={key+'_'+radioOption.value}
								value={radioOption.value}
								component={RadioButton} 
								name={option.name}
								label={radioLabel}
								icon={icons[radioOption.iconName]}
							/>							
						})}
					</LabelRadioGroup>
				</div>

			case 'color':
				return <Field
					className={className} 
					key={key}
					component={ColorField} 
				    name={option.name}
			        label={label}
					isOverriding={option.isOverriding}
					overrideReset={option.overrideReset}
				/>

			case 'details-menu':

				if (option.toggle){
					return (
						<DetailsMenu 
							key={key+'-details-menu'}
							text={label}
							checkbox={renderUIFromJSON([option.toggle], activeOptions, parentComponent, customComponentMap)}
						>
							{renderUIFromJSON(option.children, activeOptions, parentComponent, customComponentMap)}
							<div className="uiWindow-spacer"></div>
						</DetailsMenu>
					)
				} else {
					return (
						<DetailsMenu 
							key={key}
							text={label}
							checkbox={null}

							isOverriding={option.isOverriding}
							overrideReset={option.overrideReset}
						>
							{renderUIFromJSON(option.children, activeOptions, parentComponent, customComponentMap)}
							<div className="uiWindow-spacer"></div>
						</DetailsMenu>	
					)				
				}

			case 'checkbox-color':

				let checkboxKey = key+ `${option.checkbox.key || 'option'}-${index}-${option.type}-1`;
				let colorKey = key + `${option.color.key || 'option'}-${index}-${option.type}-2`;

				let colorLabel  = option.color.labelName;
				if (option.color.customLabel && customComponentMap[option.color.customLabel]){
					colorLabel = cloneElement(
						customComponentMap[option.color.customLabel],
						{
							key: 'custom-radio-label-'+option.color.name	
						}
						
					);							
				}

	
				return (
					<div className="grid-columns-auto-square" key={'checkbox-color_'+key}>
						<Field
							className={className} 
							key={colorKey}
							component={ColorField} 
						    name={option.color.name}
					        label={colorLabel}
					        type="label-swatch"
							isOverriding={option.isOverriding}
							overrideReset={option.overrideReset}
						/>
						<Field
							className={className}
							key={checkboxKey}
							component={CheckBox}
							name={option.checkbox.name}
							label={undefined}
						/>
					</div>
				)
			case 'group':
				return <div key={'group_'+key} className={`${option.className} ${className}`}>
					<ResetButton isOverriding={option.isOverriding} overrideReset={option.overrideReset}/>
					{renderUIFromJSON(option.children, activeOptions, parentComponent, customComponentMap)}
				</div>				

			case 'spacer':
				return <div key={'spacer_'+key} className="uiWindow-spacer"></div>

			case 'more-actions':
				return <MoreActions>
					{renderUIFromJSON(option.children, activeOptions, parentComponent, customComponentMap)}					
				</MoreActions>

			case 'button':
			case 'text-button': 

				let onClick = option.onClick || null;
				if(onClick && parentComponent) {
					onClick = onClick.bind(parentComponent)
				}
				if ( option.type ==='button'){
					return <Button key={'button_'+key} onMouseDown={onClick} className={option.className + className || ''}>{label}</Button>
				} else {
					return <TextButton key={'button_'+key} onMouseDown={onClick} className={option.className + className || ''} label={label}/>
				}

			default: 
				return null;
				// return <div key={option.name+'_'+index}>{'no component:' + option.name}</div>
		}
	})	
}

export {renderUIFromJSON}