import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Field } from 'formik';
import FormikChangeListener from "./formik-change-listener";
import { withPropWatchers } from "../../lib/shared-css";
import getGlobalCSSParser from "../../lib/shared-css/get-global-parser";
import { TypographySelect, TypographyMenu } from "./";
import { HotKey } from "@cargo/ui-kit/hotkey/hotkey";
import { Button, Input, MoreActions } from "@cargo/ui-kit";
import { store } from '../../index';

import _ from 'lodash';

import * as actions from "../../actions";
import { TextStyleDefaults } from "../../defaults/text-style-defaults";
import { subscriptions } from "../../lib/shared-css/global-css-subscriptions";

import { commands } from '../../lib/inline-editor';

export const TextStyleSelect = ({type, PIDBeingEdited, isMobileData, openingTextStyle, ...props}) => {

	useEffect(() => {

		// listen for text style changes
		const parser = getGlobalCSSParser();

		const onTextStyleChange = (evt) => {
			setSubscribedSelectors(subscriptions.get("text-style-selectors"))
		};

		parser.subscribe('--text-style', onTextStyleChange);

		return () => {
			parser.unsubscribe(onTextStyleChange);
		}

	}, [])

	const getLastTextStylesOption = () => {
		const localStorage = window.store.getState().adminState.localStorage;
		return localStorage['ui-textStylesWindow-lastSeen'] ?? null;
	}

	const setLastTextStylesOption = option => {
		// run a deep equality check because getLastTextStylesOption() === option is always false
		if(_.isEqual(getLastTextStylesOption(), option)) {
			return;
		}

		store.dispatch({
			type: actions.actionTypes.UPDATE_ADMIN_STATE, 
			payload: {
				localStorage: {
					['ui-textStylesWindow-lastSeen']: option
				}
			}
		});

	};

	const newStyleInput = useRef(null);
	const [selectedIndex, setSelectedIndex] = useState(null);
	const [subscribedSelectors, setSubscribedSelectors] = useState(subscriptions.get("text-style-selectors"));

	const [currentTextStyleMenuTag, setCurrentTextStyleMenuTag] = useState(openingTextStyle?.tag ?? getLastTextStylesOption() ?? 'bodycopy');
	const [showCreateNewFlow, setShowCreateNewFlow] = useState(props.showNewTextStyleFlow);
	const [showCopyFlow, setShowCopyFlow] = useState(false);

	useEffect(() => {

		if(openingTextStyle && openingTextStyle.tag !== currentTextStyleMenuTag) {
			setCurrentTextStyleMenuTag(openingTextStyle.tag)
		}

	}, [openingTextStyle])


	useEffect(() => {
		if ( props.showNewTextStyleFlow !== showCreateNewFlow) {
			setShowCreateNewFlow(props.showNewTextStyleFlow)
		}
	}, [props.showNewTextStyleFlow])

	const refreshTypographyMenus = useCallback(() => {
		setCurrentTextStyleMenuTag('bodycopy');
	}, [setCurrentTextStyleMenuTag]);

	const typographySelectors = useMemo(() => {

		const defaults = _.filter(TextStyleDefaults, textStyle => textStyle.name !== 'space');

		return [...defaults, ...subscribedSelectors]
	}, [subscribedSelectors])

	const currentTextStyle = useMemo(()=> {

		const foundTextStyle = typographySelectors.find((textStyle) => {
			return textStyle.tag.trim() === currentTextStyleMenuTag.trim();
		})
		
		return foundTextStyle ?? _.find(typographySelectors, {tag: 'bodycopy'});

	}, [typographySelectors, currentTextStyleMenuTag])

	const selector = useMemo(()=> type === 'local' ? `[id="${PIDBeingEdited}"] ${currentTextStyle?.tag}` : `${currentTextStyle?.tag}`, [currentTextStyle, PIDBeingEdited, type]);

	useEffect(()=> {

		setLastTextStylesOption(currentTextStyle.tag);

		return () => {
			setLastTextStylesOption(currentTextStyle.tag)
		}
	}, [currentTextStyle]);

	useEffect(()=> {
		setSelectedIndex(typographySelectors.indexOf(currentTextStyle))
	}, [typographySelectors, currentTextStyle, setSelectedIndex])

	useEffect(()=> {
		if( newStyleInput.current ){
			window.requestAnimationFrame(()=>{
				newStyleInput.current?.focus();
			})
			
		}
	}, [showCreateNewFlow])

	const showCopySelectorFlow = (selector) => {
		setShowCopyFlow(true)
		setShowCreateNewFlow(true)
	}

	const onChange = useMemo(() => {

		return changes => {

			const newTextStyleTag = changes['current_text_style_menu_tag'];

			if (newTextStyleTag === 'add_new') {
				// switch to global styles if we are on local
				if (type === 'local') {
					props.createNewTextStyle()
				} else {
					setShowCreateNewFlow(true);
				}
				
			} else if(newTextStyleTag) {
				setCurrentTextStyleMenuTag(newTextStyleTag);
			}
			
		}

	}, ['']);

	const renameCurrentTextStyle = (newName) => {

		const parser = getGlobalCSSParser();
		const textStyleIdentifierPropWatcher = parser.getPropWatcher(currentTextStyle?.tag, '--text-style');

		if(textStyleIdentifierPropWatcher && newName) {

			// update the name
			textStyleIdentifierPropWatcher.setValue(`"${newName.replace(/[\"]/g, '')}"`);

			// clean watcher
			textStyleIdentifierPropWatcher.destroy();
		}

	}

	const handleNewStyleAdded = (name, tag) => {

		// setup the copy
		if(name && name?.length > 0 ){

			// create the CSS for the new selector in the stylesheet
			const parser = getGlobalCSSParser();

			const copyTextStyle = showCopyFlow ? currentTextStyle : null;
			const copyFromSelector = copyTextStyle ? copyTextStyle.tag : 'bodycopy';
			// these classes are used in the markup of the site outside of the page content
			const blacklist = ['.home', '.editing', '.content', '.pages', '.page', '.page-layout', '.page-content',
								'.pinned', '.pinned-top', '.pinned-bottom', '.fixed',
								'.overlay', '.top-overlay', '.overlay-open',
								'.backdrop', '.backdrop-contents', '.visible', '.inside', '.loaded',
								'.thumbnail', '.page-title', '.tags', '.tag-link', '.linked',
								'.quick-view', '.quick-view-navigation', '.wallpaper-navigation',
								'.dark', '.stacked-page', '.feed', '.cart', '.zoomable', '.open-opacity'];


			const allRules = parser.getParsedRules();

			let hasCollision = true;
			let collisionCounter = 0;
			let uniqueClassName = `.${tag}`;

			while(hasCollision) {
                  
				let foundCollision = false;

				for(let i = 0; i < allRules.length; i++) {

					const selectorParts = allRules[i].parsedSelectors.map(s => s.content).join(' ').split(/\s+/).concat(...blacklist);

					if(selectorParts.includes(uniqueClassName)) {

						uniqueClassName = `.${tag}-${++collisionCounter}`
						foundCollision = true;
						break;

					}

				}

				hasCollision = foundCollision;

			}

			const parsedRulesList = parser.getParsedRules([
				copyFromSelector,
				copyFromSelector + ' a',
				copyFromSelector + ' a:hover',
				copyFromSelector + ' a:active',
				'.mobile ' + copyFromSelector,
				'.mobile ' + copyFromSelector + ' a',
				'.mobile ' + copyFromSelector + ' a:hover',
				'.mobile ' + copyFromSelector + ' a:active',
			]);

			if(parsedRulesList.length === 0) {
				console.error('Unable to find CSS rules to fork from. Revert back to defaults.');
				return;
			};

			let concatenatedRules = '';

			parsedRulesList.forEach((rule, index) => {

				const {parsedSelectors, parsedBlock} = rule;

				let forkedCSS = parsedBlock.content.substring(1, parsedBlock.content.length-1).trim();

				// remove any old text-style identifiers
				forkedCSS = forkedCSS.replace(/\n?--text-style:\s*".*";?\n?/gi, '');

				// normalize whitespace
				forkedCSS = forkedCSS.replace(/\n\s*/g, '\n\t')

				// create the new selector. From 'bodycopy a:hover' to '.new-selector a:hover'
				const newSelector = parsedSelectors[0].content.replace(copyFromSelector, uniqueClassName);
				
				// add a variable identifier to the main selector ('bodycopy' for example)
				const textStyleIdentifier = parsedSelectors[0].content === copyFromSelector ? `\n\t--text-style: "${name}";` : ``;
				
				// replace multiple breaks with a single break
				forkedCSS = forkedCSS.replace(/\n{1,}/g, '\n')

				// create new rule string
				concatenatedRules += `\n\n${newSelector} {${textStyleIdentifier}\n\t${forkedCSS.trim()}\n}`;

			});

			if(concatenatedRules.length > 0) {

				const textStyleSelectors = [...TextStyleDefaults, ...Array.from(subscriptions.get("text-style-selectors"))].map(sub => sub.tag);

				const lastTextStyleRule = _.findLast(allRules, rule => {

					// check if the rule's selector matches any of our text style classNames
					return textStyleSelectors.find(selector => 
						// do not insert new text styles inside of media query blocks
						rule.parsedMediaQueries === null
						&& (
							rule.parsedSelectors[0]?.content.trim().startsWith(selector) 
							|| rule.parsedSelectors[0]?.content.trim().endsWith(selector)
						)
					);

				});

				// insert it into the stylesheet
				parser.insertCSS(concatenatedRules, lastTextStyleRule?.parsedRule.end);
			}

			// get out of this "create new" flow
			setShowCreateNewFlow(false);
			setShowCopyFlow(false)

			// update prop watchers and menu
			setCurrentTextStyleMenuTag(uniqueClassName);

			// Apply new style to current selection
			commands["textstyles"].execute(tag);
			
		}

	}

	const doAddNewStyle = () => {

		const rawName = newStyleInput.current.value;

		if(!rawName) {
			return;
		}
		
		const name = rawName.replace(/[\"]/g, '');
		// kill numbers and whitespace at the start of the class name (browser's wont let you do this) and replace spaces
		let tag  = rawName
			.replace(/[^a-zA-Z-\d\s]/g, '')
			.replace(/^[\d\s]+/g, '')
			// replace spaces with dashes
			.replace(/\s/g, '-')
			// Replace multiple dashes with a single dash
			.replace(/-{2,}/g, '-')
			.toLowerCase();

		if(tag === "" || tag === "-") {
			tag = 'style-' + typographySelectors.length;
		}

		let isExistingStyleName = typographySelectors.some((obj)=> {
			if(    obj.name.toLowerCase() == name.toLowerCase()
				|| obj.name.toLowerCase() == tag
				|| obj.tag  == tag ){
				return true
			}
		})

		if( isExistingStyleName ){

			document.dispatchEvent(new CustomEvent('open-remote-alert', {
				detail: {
					message: '“'+name+'” already exists.',
				}
			}));

		} else {
			handleNewStyleAdded(name, tag); //run it to reset the form
		}
	}

	return (
		<>

			{showCreateNewFlow ? 
			// show the "new style" form
				<>
				<Formik
					// this is important for websocket updating
					enableReinitialize
					// defaults are set here
					initialValues={{
						currentTextStyle,
						showCreateNewFlow,
						new_text_style_name: showCopyFlow === true ? currentTextStyle.name + ' copy' : '',
					}}
					onSubmit={values => {
						console.log('submit');
					}}
				>
					{props => (
						<form onSubmit={(e)=>{
							// Reloads window by default - prevent that!
							e.preventDefault();
						}}>

							<FormikChangeListener onChange={onChange}/>
							<div className="ui-group">
								<Field
									component={Input} 
									name="new_text_style_name"
									placeholder="Enter new Text Style name..."
									innerRef={newStyleInput}
								/>
							</div>
							<MoreActions className="right">
								<Button 
									button-style="rounded-2"
									type="cancel"
									label="Cancel"
									onMouseDown={()=>{
										setShowCreateNewFlow(false)
										setShowCopyFlow(false)
									}}
								/>

								<Button 
									button-style="rounded-2"
									type="submit"
									label="OK"
									onMouseDown={()=> {
										doAddNewStyle();
									}}
								/>

								<HotKey 
									hotkey="enter"
									config={{ keyCode: 13 }}
									callback={()=>{
										doAddNewStyle();
									}}
									scope="window"
								/>

							</MoreActions>
						</form>
					)}
				</Formik>
				</>

			: // show switcher

				<div className="typography-menu ui-group">

					<TypographySelect 
						typographySelectors={typographySelectors} 
						selectedIndex={selectedIndex}
						showCreateNewFlow={showCreateNewFlow}
						currentTextStyle={currentTextStyle}
						onChange={onChange}
						isMobileData={isMobileData}
						selector={selector}
						refreshTypographyMenus={refreshTypographyMenus}
						setShowCreateNewFlow={setShowCreateNewFlow}
						showCopySelectorFlow={showCopySelectorFlow}
						renameCurrentTextStyle={renameCurrentTextStyle}
					 />

					<TypographyMenu 
						key={selector}
						selector={selector}
						selectorReadable={currentTextStyle.name}
						type={type}
						refreshTypographyMenus={refreshTypographyMenus}
						isLocal={type === 'local'}
						setShowCreateNewFlow={setShowCreateNewFlow}
						showCopySelectorFlow={showCopySelectorFlow}
						isMobileData={isMobileData}
					/>

				</div>
			}

		</>
	)

}

