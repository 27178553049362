import { TextButton, CheckBox, Scrubber, DetailsMenu } from "./index";
import { Button, Input, MoreActions, Select } from "@cargo/ui-kit";
import { Formik, Field } from 'formik';
import _ from 'lodash';
import { overlayDefaults } from "../../defaults/overlay-defaults";

export const processOverlayChanges = ({key, changes, overlay}) => {

    // create a new object to mutate
    const newOverlay = _.cloneDeep(overlay);
    
    switch (key) {
        case 'overlay_close_on_clickout':
            newOverlay.closeOnClickout = changes['overlay_close_on_clickout'];
            break;
        case 'overlay_close_on_navigate':
            newOverlay.closeOnNavigate = changes['overlay_close_on_navigate'];
            break;
        case 'overlay_open_below':
            newOverlay.openBelow = changes['overlay_open_below'];
            break;
        default: 
            break;
    }

    if (key.startsWith('overlay_animate_on_open_') || key.startsWith('overlay_animate_on_close_')) {
        const keyEnd = key.replace('overlay_animate_on_open_', '').replace('overlay_animate_on_close_', '');
        const property = keyEnd?.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());
        console.log("PROPERTY", property)
        if (key.includes('open_')) {
            newOverlay.animateOnOpen = {
                ...newOverlay.animateOnOpen,
                [property]: changes[key]
            }
        }
        if (key.includes('close_')) {
            newOverlay.animateOnClose = {
                ...newOverlay.animateOnClose,
                [property]: changes[key]
            }
        }
    }

    if (key === 'overlay_animate_on_open' && changes[key] !== undefined && changes[key] === true) {
        newOverlay.animateOnOpen = {
            fade: true,
            speed: 0.3,
            slideAmount: 0,
            slideDirection: null,
            wipeShape: null,
            scaleAmount: 0,
            rotateAmount: 0,
        }
    }

    if (key === 'overlay_animate_on_open' && changes[key] !== undefined && changes[key] === false) {
        newOverlay.animateOnOpen = false;
    }

    if (key === 'overlay_animate_on_close' && changes[key] !== undefined && changes[key] === true) {
        newOverlay.animateOnClose = {
            fade: true,
            speed: 0.3,
            slideAmount: 0,
            slideDirection: null,
            wipeShape: null,
            scaleAmount: 0,
            rotateAmount: 0,
        }
    }

    if (key === 'overlay_animate_on_close' && changes[key] !== undefined && changes[key] === false) {
        newOverlay.animateOnClose = false;
    }

    // Handle setting defaults when appropriate
    switch (key) {
        case 'overlay_animate_on_open_fade':
            if (
                changes[key] === true &&
                overlay.animateOnOpen.speed === 0
            ) {
                newOverlay.animateOnOpen.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_open_slide_direction':
            if (
                overlay.animateOnOpen.slideAmount === 0
            ) {
                newOverlay.animateOnOpen.slideAmount = 3;
            }
            if (
                overlay.animateOnOpen.speed === 0
            ) {
                newOverlay.animateOnOpen.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_open_slide_amount':
            if (
                overlay.animateOnOpen.slideDirection === null
            ) {
                newOverlay.animateOnOpen.slideDirection = 'right';
            }
            if (
                overlay.animateOnOpen.speed === 0
            ) {
                newOverlay.animateOnOpen.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_open_scale_amount':
            if (
                overlay.animateOnOpen.speed === 0
            ) {
                newOverlay.animateOnOpen.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_open_rotate_amount':
            if (
                overlay.animateOnOpen.speed === 0
            ) {
                newOverlay.animateOnOpen.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_open_wipe_shape':
            if (
                overlay.animateOnOpen.speed === 0
            ) {
                newOverlay.animateOnOpen.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_close_fade':
            if (
                changes[key] === true &&
                overlay.animateOnClose.speed === 0
            ) {
                newOverlay.animateOnClose.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_close_slide_direction':
            if (
                overlay.animateOnClose.slideAmount === 0
            ) {
                newOverlay.animateOnClose.slideAmount = 3;
            }
            if (
                overlay.animateOnClose.speed === 0
            ) {
                newOverlay.animateOnClose.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_close_slide_amount':
            if (
                overlay.animateOnClose.slideDirection === null
            ) {
                newOverlay.animateOnClose.slideDirection = 'right';
            }
            if (
                overlay.animateOnClose.speed === 0
            ) {
                newOverlay.animateOnClose.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_close_scale_amount':
            if (
                overlay.animateOnClose.speed === 0
            ) {
                newOverlay.animateOnClose.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_close_rotate_amount':
            if (
                overlay.animateOnClose.speed === 0
            ) {
                newOverlay.animateOnClose.speed = 0.3;
            }
            break;
        case 'overlay_animate_on_close_wipe_shape':
            if (
                overlay.animateOnClose.speed === 0
            ) {
                newOverlay.animateOnClose.speed = 0.3;
            }
            break;
        default:
            break;
    }

    return newOverlay;

}

export const OverlayControls = ({overlay, values, disabled, pid, onChange}) => {

    const openIsDefault = JSON.stringify(overlay.animateOnOpen) === JSON.stringify(overlayDefaults.animateOnOpen) || overlay.animateOnOpen === false ? true : false;
    const closeIsDefault = JSON.stringify(overlay.animateOnClose) === JSON.stringify(overlayDefaults.animateOnClose) || overlay.animateOnClose === false ? true : false;

    const overlayEasingOptions = [{
        label: 'Linear',
        value: 'linear'
    },
    {
        label: 'Ease in',
        value: 'ease-in'
    },
    {
        label: 'Ease out',
        value: 'ease-out'
    },
    {
        label: 'Ease in out',
        value: 'ease-in-out'
    },
    {
        label: 'Smooth',
        value: 'smooth'
    },
    {
        label: 'Bounce',
        value: 'bounce'
    },
    {
        label: 'Elastic',
        value: 'elastic'
    },
    {
        label: 'Hesitate',
        value: 'hesitate'
    }];

    const overlaySlideOpenDirectionOptions = [{
        label: '← Left',
        value: 'right'
    },
    {
        label: '→ Right',
        value: 'left'
    },
    {
        label: '↓ Down',
        value: 'top'
    },
    {
        label: '↑ Up',
        value: 'bottom'
    }];

    const overlaySlideCloseDirectionOptions = [{
        label: '← Left',
        value: 'left'
    },
    {
        label: '→ Right',
        value: 'right'
    },
    {
        label: '↓ Down',
        value: 'bottom'
    },
    {
        label: '↑ Up',
        value: 'top'
    }];

    const overlayWipeShapeOptions = [{
        label: '← Left',
        value: 'right'
    },
    {
        label: '→ Right',
        value: 'left'
    },
    {
        label: '↓ Down',
        value: 'top'
    },
    {
        label: '↑ Up',
        value: 'bottom'
    },
    {
        label: 'Circle',
        value: 'circle'
    },
    {
        label: 'Square',
        value: 'square'
    },
    {
        label: 'Diamond',
        value: 'diamond'
    }];

    const animateOnOpenInUse = overlay.animateOnOpen && JSON.stringify(overlay.animateOnOpen) !== JSON.stringify(overlayDefaults.animateOnOpen);
    const animateOnCloseInUse = overlay.animateOnClose && JSON.stringify(overlay.animateOnClose) !== JSON.stringify(overlayDefaults.animateOnClose);


    return (
        <>
            <Field 
                component={CheckBox}
                name="overlay_close_on_clickout"
                label="Close on clickout"
                parentClass="combo-input"
                disabled={disabled}
            />
            <Field 
                component={CheckBox}
                name="overlay_close_on_navigate"
                label="Close on navigate"
                parentClass="combo-input"
                disabled={disabled}
            />
            <DetailsMenu 
                text="Open transition"
                sessionStorage={`overlay-window-p${pid}-open-transition`}
                className={animateOnOpenInUse ? 'in-use' : null}
                disabled={disabled}
            >
                <div className="grid-columns-even">
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_open_speed"
                        numberOnlyMode={true}
                        suffix="s"
                        step={0.01}
                        min={0}
                        max={100}
                        value={overlay.animateOnOpen ? overlay.animateOnOpen.speed : 0}
                        label="Speed"
                        className="combo-input"
                    />
                    <Field
                        component={Select}
                        name="overlay_animate_on_open_easing"
                        className={`combo-select`}
                        // prefix="Easing:"
                    >
                        {
                            overlayEasingOptions.map(data => {
                                return <option 
                                    key={data.value} 
                                    value={data.value}
                                >
                                    {data.label}
                                </option>
                            })
                        }
                    </Field>
                </div>
                <Field 
                    component={CheckBox}
                    name="overlay_animate_on_open_fade"
                    label="Fade"
                    parentClass="combo-input"
                />
                <Field
                    component={Select}
                    name="overlay_animate_on_open_slide_direction"
                    className={`combo-select`}
                    prefix="Slide:"
                >
                    {
                        [
                            <option key="default" value="default">None</option>,
                            overlaySlideOpenDirectionOptions.map(data => {
                                return <option 
                                    key={data.value} 
                                    value={data.value}
                                >
                                    {data.label}
                                </option>
                            })
                        ]
                    }
                </Field>
                { values && values['overlay_animate_on_open_slide_direction'] !== 'default' ? (
                    <Field
                    component={Scrubber}
                    name="overlay_animate_on_open_slide_amount"
                    numberOnlyMode={true}
                    suffix="%"
                    step={1}
                    min={0}
                    max={100}
                    value={overlay.animateOnOpen ? overlay.animateOnOpen.slideAmount : 0}
                    label="Slide amount"
                    className="combo-input"
                />): null}
                <Field
                    component={Select}
                    name="overlay_animate_on_open_wipe_shape"
                    className={`combo-select`}
                    prefix="Wipe:"
                >
                    {
                        [
                            <option key="default" value="default">None</option>,
                            overlayWipeShapeOptions.map(data => {
                                return <option 
                                    key={data.value} 
                                    value={data.value}
                                >
                                    {data.label}
                                </option>
                            })
                        ]
                    }
                </Field>
                <div className="grid-columns-even">
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_open_scale_amount"
                        numberOnlyMode={true}
                        suffix="%"
                        step={1}
                        min={-100}
                        max={100}
                        value={overlay.animateOnOpen ? overlay.animateOnOpen.scaleAmount : 0}
                        label="Grow"
                        className="combo-input"
                    />
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_open_rotate_amount"
                        numberOnlyMode={true}
                        step={1}
                        value={overlay.animateOnOpen ? overlay.animateOnOpen.rotateAmount : 0}
                        label="Rotate"
                        className="combo-input"
                    />
                </div>
                { openIsDefault === false ? (
                    <MoreActions className="left">
                        <TextButton className="code" label={<><em>×</em> Reset</>} onMouseDown={ () => {
                            onChange({
                                'overlay_animate_on_open': false,
                                'overlay_animate_on_open_fade': false,
                                'overlay_animate_on_open_speed': 0,
                                'overlay_animate_on_open_slide_direction': 'default',
                                'overlay_animate_on_open_slide_amount': 0,
                                'overlay_animate_on_open_wipe_shape': 'default',
                                'overlay_animate_on_open_scale_amount': 0,
                                'overlay_animate_on_open_rotate_amount': 0,
                            })
                        } }/>
                    </MoreActions>
                ) : null }
            </DetailsMenu>
            <DetailsMenu 
                text="Close transition"
                className={animateOnCloseInUse ? 'in-use' : null}
                sessionStorage={`overlay-window-p${pid}-close-transition`}
                disabled={disabled}
            >
                <div className="grid-columns-even">
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_close_speed"
                        numberOnlyMode={true}
                        suffix="s"
                        step={0.01}
                        min={0}
                        max={100}
                        value={overlay.animateOnClose ? overlay.animateOnClose.speed : 0}
                        label="Speed"
                        className="combo-input"
                    />
                    <Field
                        component={Select}
                        name="overlay_animate_on_close_easing"
                        className={`combo-select`}
                        // prefix="Easing:"
                    >
                        {
                            overlayEasingOptions.map(data => {
                                return <option 
                                    key={data.value} 
                                    value={data.value}
                                >
                                    {data.label}
                                </option>
                            })
                        }
                    </Field>
                </div>
                <Field 
                    component={CheckBox}
                    name="overlay_animate_on_close_fade"
                    label="Fade"
                    parentClass="combo-input"
                />
                <Field
                    component={Select}
                    name="overlay_animate_on_close_slide_direction"
                    className={`combo-select`}
                    prefix="Slide:"
                >
                    {
                        [
                            <option key="default" value="default">None</option>,
                            overlaySlideCloseDirectionOptions.map(data => {
                                return <option 
                                    key={data.value} 
                                    value={data.value}
                                >
                                    {data.label.charAt(0).toUpperCase() + data.label.slice(1)}
                                </option>
                            })
                        ]
                    }
                </Field>
                { values && values['overlay_animate_on_close_slide_direction'] !== 'default' ? (
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_close_slide_amount"
                        numberOnlyMode={true}
                        suffix="%"
                        step={1}
                        min={0}
                        max={100}
                        value={overlay.animateOnClose ? overlay.animateOnClose.slideAmount : 0}
                        label="Slide amount"
                        className="combo-input"
                    />
                ) : null }
                <Field
                    component={Select}
                    name="overlay_animate_on_close_wipe_shape"
                    className={`combo-select`}
                    prefix="Wipe:"
                >
                    {
                        [
                            <option key="default" value="default">None</option>,
                            overlayWipeShapeOptions.map(data => {
                                return <option 
                                    key={data.value} 
                                    value={data.value}
                                >
                                    {data.label}
                                </option>
                            })
                        ]
                    }
                </Field>
                <div className="grid-columns-even">
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_close_scale_amount"
                        numberOnlyMode={true}
                        suffix="%"
                        step={1}
                        min={-100}
                        max={100}
                        value={overlay.animateOnClose ? overlay.animateOnClose.scaleAmount : 0}
                        label="Grow"
                        className="combo-input"
                    />
                    <Field
                        component={Scrubber}
                        name="overlay_animate_on_close_rotate_amount"
                        numberOnlyMode={true}
                        step={1}
                        value={overlay.animateOnClose ? overlay.animateOnClose.rotateAmount : 0}
                        label="Rotate"
                        className="combo-input"
                    />
                </div>
                { closeIsDefault === false ? (
                    <MoreActions className="left">
                        <TextButton className="code" label={<><em>×</em> Reset</>} onMouseDown={ () => {
                            onChange({
                                'overlay_animate_on_close': false,
                                'overlay_animate_on_close_fade': false,
                                'overlay_animate_on_close_speed': 0,
                                'overlay_animate_on_close_slide_direction': 'default',
                                'overlay_animate_on_close_slide_amount': 0,
                                'overlay_animate_on_close_wipe_shape': 'default',
                                'overlay_animate_on_close_scale_amount': 0,
                                'overlay_animate_on_close_rotate_amount': 0,
                            })
                        } }/>
                    </MoreActions>
                ) : null }
            </DetailsMenu>
        </>
    )
}